$(function () {
    $("#owl-demo").owlCarousel({
    	autoPlay: true,
        navigation: true,
        navigationText: ["<i class='fa fa-angle-left'>","<i class='fa fa-angle-right'>"],
        slideSpeed: 300,
        pagination: false,
        paginationSpeed: 400,
        singleItem: true
    });
});










